body {
    background: #ecf3f3;
}

.particle {
	position: absolute;
	border-radius: 15%;
}

@for $i from 1 through 30 {
	@keyframes particle-animation-#{$i} {
		100% {
			transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * .5px));
		}
	}
	
	.particle:nth-child(#{$i}){
		animation: particle-animation-#{$i} 60s infinite;
		$size: random(60) + 25 + px;
		opacity: random(18)/100;
		height: $size;
		width: $size;
		animation-delay: -$i * .5s;
		transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * .5px));
		background: hsl(random(10), 20%, 80%);
	}
}