

.particle-blue {
	position: absolute;
	border-radius: 15%;
	border: 5px solid white;
}

@for $i from 1 through 30 {
	@keyframes particle-animation-#{$i} {
		100% {
			transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * .5px));
		}
	}
	
	.particle-blue:nth-child(#{$i}){
		animation: particle-animation-#{$i} 60s infinite;
		$size: random(100) + 40 + px;
		opacity: random(18)/100;
		height: $size;
		width: $size;
		animation-delay: -$i * .5s;
		transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * .5px));

	}
}